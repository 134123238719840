/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

/* Global Styles */
body {
  font-family: 'Anton', sans-serif;
  text-transform: uppercase;
}
.video-js {
  width: 100% !important;
  height: 400px !important;
  background-color: black;
}
.portrait-video {
  width: 100%;
  height: auto;
  max-height: 100vh; /* Ensures it doesn’t get too large */
  object-fit: contain; /* Ensures proper aspect ratio without cropping */
  background-color: black; /* Adds black bars on sides if needed */
}

.call-coach-btn {
  background-color: #495057;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  border-radius: 50px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding: 15px 30px;
  white-space: nowrap;
}

/* Mobile: Full-width at bottom */
@media (max-width: 768px) {
  #footer{
    padding-bottom: 100px !important;
  }
  .call-coach-btn {
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0;
    text-align: center;
    padding: 15px;
  }
}

/* Navigation and Hero Section */
.navbar {
  position: absolute;
  width: 100%;
  z-index: 9999;
}

.logo {
  width: 135px;
}

.banner-image {  
  background: url('/public/images/RedDevilsHeroImage.jpg') no-repeat center center;
  background-size: cover;
  background-position-y: top;
  height: 600px;
  width: 100%;
}

.bg-red {
  background-color: #500004;
}

/* Hero Section Titles */
.hero-title {
  font-size: 4rem;
}

.hero-subtitle {
  font-size: 1.5rem;
}

/* Button Styles */
.btn-primary {
  background-color: #B80808; /* Red color for the button */
  border: none;
}

.btn-primary:hover {
  background-color: #cc0000; /* Darker red on hover */
}
/* Section Styles */
.section-title {
  font-size: 4rem;
  margin-bottom: 20px;
}

.section-text {
  font-size: 1.1rem;
  color: #495057;
  line-height: 1.6;
}

/* Coach Image */
.coach-image {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Contact Us Section */
.register-container {
  background-color: #B80808;
}

.form-label {
  font-size: 1rem;
}

.form-control {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

/* Footer Styles */
.footer {
  padding: 40px 0;
  background-color: #343a40;
  color: #ffffff;
}

.footer h5 {
  font-size: 2.75rem;
  margin-bottom: 20px;
}

.footer ul {
  padding-left: 0;
  list-style: none;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer ul li a {
  color: #ffffff;
  text-decoration: none;
}

.footer ul li a:hover {
  text-decoration: underline;
}

.footer .social-media-icons a {
  color: #ffffff;
  margin: 0 10px;
  font-size: 1.5rem;
}

.footer .social-media-icons a:hover {
  color: #cccccc;
}


